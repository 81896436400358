:root {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  --contentMaxHeight: 75vh;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {
  background: white;
  padding: 10px 0px;
  
}
.main > h2 {
  margin: 0;
  text-align: center;
}
.main-wrapper{
  display: flex;
  flex-direction: column;
  min-height: var(--contentMaxHeight);
}
.mainContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* gap: 10px; */
  width: 88vw;
  /* padding: 8px; */
  border: 1px solid black;
  justify-content: space-between;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .mainContainer {
    width: 98vw;
  }
}

.sidebar {
  flex: 1;
  /* background: #b4f2f3; */
  /* justify-content: center; */
  /* border-radius: 5px; */
  padding: 10px 15px;
  /* min-width: 200px; */
  /* max-width: 300px; */
  flex-direction: row;
  align-items: center;
  display: flex;
  gap: 15px;
  /* height: var(--contentMaxHeight); */
  /* box-shadow: 4px 4px 7px 1px #000; */
  margin-bottom: 0px;
  /* border-bottom: 1px solid black; */
  /* background-color: #570000; */
}

.mainbar {
  flex: 20;
  background: white;
  color: white;
  border-radius: 5px;
  /* padding: 20px 10px; */
  /* min-width: 400px; */
  /* max-width: 600px; */
  display: flex;
  /* justify-content: center; */
  width: 65vw;
  flex-direction: column;
  min-height: var(--contentMaxHeight);
  
}


.EditName {
  color: #ff497c;
  font-weight: 700;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.sendButton {
  background: #786ef6;
  padding: 10px 40px;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-bottom: 5px;
  &:hover {
    background: #9a95e0;
  }
}


.heading {
  color: black !important;
  font-family: "Grenze Gotisch";
  word-spacing: 5px;
  letter-spacing: 2px;
  font-size: 100px;
  font-size: 35px !important;
}

.inputText {
  border-radius: 5px;
  height: 30px;
  width: 80%;
  outline: none;
  border: none;
  text-align: center;
  margin: 0;
}

.UploadButton {
  width: 80%;
  height: 35px;
  background-color: #b5b4f3;
  border: none;
  border-radius: 5px;
  text-align: center;
}
.UploadButton:hover {
  background-color: #9b99ec;
}

input[type="file"] {
  display: none;
}

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 10px;
  gap: 10px;
}

.inputLabel {
  min-width: 5rem;
}

.custom-file-upload {
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  padding: 8px;
  cursor: pointer;
  background-color: #570000;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  width: max-content;
  height: 35px;
  color: white;
  vertical-align: middle;
  position: relative;
}

.custom-file-upload:hover {
  background-color: #c44141;
}

.tooltip {
  z-index: 99;
  display: none;
  position: absolute;
  top: 30px;
  left: 50px;
  width: max-content;
  background: #c44141;
  border-radius: 5px;
  font-size: 16px;
  padding: 5px;
  cursor: pointer;
  border: 0.5px solid black;
}

.videoCentered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--contentMaxHeight) - 90px);
  margin-top: 8px;
}

.videoContainer {
  text-align: center;
  position: relative;
  width: max-content;
  height: max-content;
  display: inline-block;
  /* border: 1px solid black; */
  margin: 8px auto;
}

.App {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 50px;
}
form {
  margin-bottom: 20px;
}

input {
  margin: 10px;
}

video {
  margin-top: 20px;
  max-width: 100%;
}

.processed_videos_container {
  display: flex;
  /* width: 100%; */
  overflow-x: auto;
  gap: 10px;
  /* background: #edf4cb; */
  background: #ea7373;
  margin: 10px;
  flex: 1;
  border-radius: 5px;
}

.textContainer {
  background: rgb(223, 243, 223);
  border-radius: 20px;
  padding: 20px 15px;
  margin: 10px auto;
  /* width: 200px;
  height: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80%;
}

.textContainer div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.upload-container {
  border: 2px dashed #ff497c;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background-color: #fff5f7;
  position: relative;
  width: 100%;
  min-height: inherit;
  /* width: 300px;
  height: 200px; */
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-button {
  background-color: #ff497c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 30px;
  font-weight: bold;
}

.upload-button:hover {
  background-color: #ff6b8d;
}

.paste-text {
  color: #7d7d7d;
  font-size: 12px;
}
