.draggable-container {
  display: inline-block;
  border: 1px solid #000;
  /* z-index: 1; */
  position: absolute !important;

}



.resizable-box {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
  /* overflow: hidden; */
}

.editable-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  /* padding: 10px; */
  box-sizing: border-box;
}

.editable-box input {
  width: 100%;
  border: none;
  outline: none;
  /* padding: 5px; */
  box-sizing: border-box;
  text-align: center;
}

.editable-box span {
  text-align: center;
}

.context-menu {
  position: relative;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* z-index: 1000; */
  padding: 5px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  width: 90%;
  border-radius: 5px;
}

.context-property {
  height: 35px;
  text-align: center;
  margin-right: 10px;
  width: 100px;
  padding: 2px 15px;
  border: none;
  outline: none;
}

.context-menu label {
  display: flex;
  justify-content: space-between;
  /* gap: 10px; */
  align-items: center;
  width: 100%;
  height: 30px;
}

.textInput {
  background: none;
  outline: none;
  box-shadow: none;
  border: none;
}